import React from 'react'

class Picture extends React.Component {

	render() {
		return(<div className='picture center'>
			{this.props.imageStack}
		</div>)
	}
}

export default Picture