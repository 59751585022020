import './App.css';
import Controller from './canvas/Controller'

function App() {
  return (
    <div className="App">
      {/*<Menu/>*/}
      <Controller/>
    </div>
  );
}

export default App;