import React from 'react'
import Canvas from './Canvas'

class Controller extends React.Component {

	constructor(props) {
		super(props);
		this.importAssets = this.importAssets.bind(this);

	}

	findName(phrase) {
		return phrase.match(/\/[a-zA-z0-9\s]+\./gm)[0].slice(1,phrase.match(/\/[a-zA-z0-9\s]+\./gm)[0].length-1)
	}

	findAssetName(phrase) {
        return phrase.match(/\.\/[a-zA-z0-9\s]+\//gm)[0].slice(2,phrase.match(/\.\/[a-zA-z0-9\s]+\//gm)[0].length-1)
    }

    preLoad(itemUrl) {  
	    let img = new Image();
	    img.src = itemUrl;
	    ////console.log('preload')
	};
    //Import gives 3 packages 1 for categories, assets, and items each respectively.
	importAssets() {
		let r =require.context('../canvas/groups', true, /\.png$/)
		let categoryPackage = {}
		r.keys().forEach((key)=> {
			categoryPackage[this.findName(key)] = r(key).default
			this.preLoad(r(key).default)
		})
		let itemPackage = {}
		let assetPackage = {}
		r = require.context('../canvas/categories', true, /\.png$/)
		r.keys().forEach((key)=> {
			itemPackage[this.findName(key)] = {}
			assetPackage[this.findName(key)] = r(key).default
			this.preLoad(r(key).default)
		})

		r = require.context('../canvas/assets', true, /\.png$/)

		r.keys().forEach((key)=> {
			//if else statements necessary because sometimes itemPackage[assetName][this.findName(key)] doesn't exist yet
			//remove the groups and categories issue once it's finished
			let assetName = this.findAssetName(key)
			let itemName = this.findName(key)
			if(key.includes('icons')) {
				if(itemPackage[assetName][itemName]===undefined) {
					let temp = {'icon':r(key).default}
					itemPackage[assetName][itemName] = temp
				} else {
					itemPackage[assetName][itemName]['icon'] = r(key).default
					this.preLoad(r(key).default)
				}
				
			}
		})

		r = require.context('../canvas/assets', true, /\.webp$/)

		r.keys().forEach((key)=> {
			//if else statements necessary because sometimes itemPackage[assetName][this.findName(key)] doesn't exist yet
			//remove the groups and categories issue once it's finished
			let assetName = this.findAssetName(key)
			let itemName = this.findName(key)
			if(key.includes('icons')) {
				if(itemPackage[assetName][itemName]===undefined) {
					let temp = {'icon':r(key).default}
					itemPackage[assetName][itemName] = temp
				} else {
					itemPackage[assetName][itemName]['icon'] = r(key).default
					this.preLoad(r(key).default)
				}
				
			} else if(!key.includes('groups')&&!key.includes('categories')) {
				if(itemPackage[assetName][itemName]===undefined) {
					let temp = {'item':r(key).default}
					itemPackage[assetName][itemName] = temp
				} else {
					itemPackage[assetName][itemName]['item'] = r(key).default
					this.preLoad(r(key).default)
				}
			}
		})

		return({'categoryPackage' : categoryPackage,
			'assetPackage' : assetPackage,
			'itemPackage' : itemPackage})
	}

	render() {
		////console.log('Controller rendered again')
		const {categoryPackage,itemPackage,assetPackage} = this.importAssets()

		////console.log(categoryPackage)

		const behavior = {'TOP5':{'linked':'TOP5TRIM'},'dress1':{'linked':'sash1'},'Dress2':{'linked':'Dress2Trim'},'Dress3':{'linked':'Dress3Trim'},'TOP4':{'linked':'TRIM4'},'TOP2':{'linked':'TRIM2'},'top1':{'linked':'trim1'},'accessories':{'multi-select':true},'ornaments':{'multi-select':true},'TOP3':{'linked':'TRIM3'}};
		const layerOrder = ['backgrounds','base','top1','trim1','Dress2Trim','Dress3','Dress3Trim','shoe2','Dress2','shoe1','dress1','TOP3','TRIM3','TOP2','TRIM2','TOP4','TRIM4','TOP5','TOP5TRIM','sash1','accessories','ornaments','weapons','fan1'];
		const exclusive = {'backgrounds' : 'backgrounds',
							'base' : 'base',
							'top1' : 'tops',
							'shoe1' : 'shoes',
							'shoe2' : 'shoes',
							'dress1' : 'bottom',
							'TOP2' : 'tops',
							'TOP3' : 'tops',
							'TOP4' : 'tops',
							'TOP5' : 'tops',
							'sash1' : 'linked',
							'fan1' : 'fans',
							'weapons' : 'fans',
							'TRIM2' : 'linked',
							'TRIM4' : 'linked',
							'trim1' : 'linked',
							'TRIM3' : 'linked',
							'TOP5TRIM' : 'linked',
							'accessories' : 'multi-select',
							'ornaments' : 'multi-select',
							'Dress2' : 'bottom',
							'Dress3' : 'bottom',
							'Dress2Trim' : 'linked',
							'Dress3Trim' : 'linked',}
							
		const grouping = {'bottoms':['dress1','Dress3','Dress2',],
                      		'tops':['top1','TOP3','TOP2','TOP5','TOP4'],
                            'shoes':['shoe1','shoe2'],
                            'hands':['fan1','weapons'],
                            'accessories':['accessories','ornaments'],
                            'backgrounds':['backgrounds'],};

                            //grouping order is done in animated menu


		return(<Canvas behavior={behavior}
			layerOrder = {layerOrder}
			grouping = {grouping}
			categoryPackage = {categoryPackage}
			itemPackage = {itemPackage}
			assetPackage = {assetPackage}
			exclusive = {exclusive}/>)
	}
}

export default Controller