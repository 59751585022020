import React from 'react'
import './Canvas.css'
import Picture from './Picture'
import mergeImages from 'merge-images'
import base from './CLOVE BASE.png'
import signature from './logo.png'
import Menu from './AnimatedMenu'
import resetIcon from './otherIcons/refresh.svg'
import cameraIcon from './otherIcons/camera.svg'
import downloadIcon from './otherIcons/download.svg'
import patreonIcon from './otherIcons/patreon.png'
import webtoonIcon from './otherIcons/webtoon.png'

class Canvas extends React.Component{

	constructor(props) {
		super(props)
		this.handleCanvas = this.handleCanvas.bind(this)
		this.handleMergeImages = this.handleMergeImages.bind(this)
		this.closeMerge = this.closeMerge.bind(this)
		this.handleReset = this.handleReset.bind(this)
		this.state = {style:true,exclusiveLayers : {}}
	}

	handleReset() {
		if(this.state.mergedImage) {
			this.closeMerge()
			return null
		}
		this.setState({exclusiveLayers : {}})
	}

	handleMergeImages() {
		const {behavior,layerOrder} = this.props
		const exclusiveLayers = this.state.exclusiveLayers
		let testImageStack = []
		let testImageObject = {}

		Object.keys(exclusiveLayers).forEach((key) => {
			Object.entries(exclusiveLayers[key]).forEach(([key,value])=>testImageObject[key] = value)
		})

		// layerOrder.forEach((layer) => {
		// 	if(layer === 'base') {
		// 		testImageStack.push(base)
		// 	} else if(testImageObject[layer]) {
		// 		if(behavior[layer]) {
		// 			if(behavior[layer]['linked']) {
		// 			testImageStack.push(testImageObject[layer])
		// 			if(testImageObject[behavior[layer]['linked']])
		// 				testImageStack.push(testImageObject[behavior[layer]['linked']])
		// 		} else if(behavior[layer]['multi-select']) {
		// 			Object.values(testImageObject[layer]).forEach((url) => {
		// 				testImageStack.push(url)
		// 			})
		// 		}
		// 		}
				
		// 		else {
		// 			testImageStack.push(testImageObject[layer])
		// 		}
		// 	}
		// })

		layerOrder.forEach((layer) => {
			if(layer === 'base') {
				testImageStack.push(base)
			} else if(testImageObject[layer]) {
				if(behavior[layer]) {
					if(behavior[layer]['linked']) {
						// let linkedReturn = []
						testImageStack.push(testImageObject[layer])
						// linkedReturn.push(<img alt = '' key={layer} className='picture' src = {testImageObject[layer]}/>)
						// return linkedReturn
					} else if(behavior[layer]['multi-select']) {
						Object.values(testImageObject[layer]).forEach((url) => {
							testImageStack.push(url);
							// return(<img alt = '' key={layer + url} className='picture' src = {url}/>)
					})
				}
				}
				else {
					testImageStack.push(testImageObject[layer])
					// return(<img alt = '' key={layer} className='picture' src = {testImageObject[layer]}/>)
				}
			}
		});
		
		testImageStack.push(signature)
    	mergeImages(testImageStack).then(b64=>{this.setState({mergedImage : b64})})
  }

  closeMerge() {
  	this.setState({mergedImage:undefined})
  }


	handleCanvas(asset,itemCategory,itemUrl) {
		// ////console.log(asset,itemCategory,itemUrl)
		const tempExclusive = this.state.exclusiveLayers
		// ////console.log(tempExclusive)
		const exclusiveLookup = this.props.exclusive

		let tempLayer = exclusiveLookup[asset]
		let tempItem = {}
		tempItem[asset] = itemUrl;
		if(tempLayer === 'multi-select') {
			if(tempExclusive[tempLayer] == null) {
				tempExclusive[tempLayer] = {}
			}	if(tempExclusive[tempLayer][asset] == null) {
					tempExclusive[tempLayer][asset] = {}
			}
			if(tempExclusive[tempLayer][asset][itemCategory] === itemUrl) {
				delete tempExclusive[tempLayer][asset][itemCategory]
			} else {
				tempExclusive[tempLayer][asset][itemCategory] = itemUrl
			}

		} else if(tempLayer === 'linked') {
			if(tempExclusive[tempLayer] == null)
				tempExclusive[tempLayer] = {}
			if(tempExclusive[tempLayer][asset] === itemUrl) {
				delete tempExclusive[tempLayer][asset]
			} else {
				tempExclusive[tempLayer][asset] = itemUrl
			}
		} else {
			if(tempExclusive[tempLayer]) {
				if(tempExclusive[tempLayer][asset] === itemUrl) {
					delete tempExclusive[tempLayer][asset]
				} else {
					tempExclusive[tempLayer] = tempItem
				}
			} else {
				tempExclusive[tempLayer] = tempItem
			}
		}

		//use this to filter the array and remove useless linked
		if(tempExclusive && tempExclusive['linked']) {
			let behaviors = this.props.behavior
			let linkedBehaviors = {};
			Object.keys(behaviors).forEach((key) => {
				if(behaviors[key]['linked'])
				linkedBehaviors[behaviors[key]['linked']] = key;
			})
			// //console.log(linkedBehaviors);
			Object.keys(tempExclusive['linked']).forEach((key) => {
				let searchItem = linkedBehaviors[key];
				let exists = false;
				Object.keys(tempExclusive).forEach((key) => {
					if(tempExclusive[key][searchItem] !== undefined) {
						exists = true;
					}
				})

				if(!exists) {
					delete tempExclusive['linked'][key]
				}
			})
		}
		
		this.setState({exclusiveLayers : tempExclusive})
	}

	render() {
		const exclusiveLayers = this.state.exclusiveLayers
		const selectedVariants = [];
		const selectedOptions = [];
		const {behavior,layerOrder, grouping, categoryPackage, itemPackage, assetPackage,} = this.props


		let testImageObject = {}

		Object.keys(exclusiveLayers).forEach((key) => {
			Object.entries(exclusiveLayers[key]).forEach(([key,value])=>testImageObject[key] = value)
		})

		const newImageStack = layerOrder.map((layer) => {
			if(layer === 'base') {
				return(<img alt = '' key='base' className = 'picture' src = {base}/>)
			} else if(testImageObject[layer]) {
				if(behavior[layer]) {
					if(behavior[layer]['linked']) {
						let linkedReturn = []
						linkedReturn.push(<img alt = '' key={layer} className='picture' src = {testImageObject[layer]}/>)
						selectedVariants.push(layer)
						selectedOptions.push(testImageObject[layer])
						if(testImageObject[behavior[layer]['linked']]) {
							selectedVariants.push(behavior[layer]['linked']);
							selectedOptions.push(testImageObject[behavior[layer]['linked']]); //inefficient way but this will give the green circle
						}
						return linkedReturn
					} else if(behavior[layer]['multi-select']) {
						return Object.values(testImageObject[layer]).map((url) => {
							selectedVariants.push(layer);
							selectedOptions.push(url);
							return(<img alt = '' key={layer + url} className='picture' src = {url}/>)
					})
				}
				}
				else {
					selectedVariants.push(layer);
					selectedOptions.push(testImageObject[layer]);
					return(<img alt = '' key={layer} className='picture' src = {testImageObject[layer]}/>)
				}
			}
			return null;
		});


		return(
			<div className='canvasStyle'>
			{this.state.mergedImage ? <div className='middleModal'><img alt = '' onClick={this.closeMerge} style={{'height':'100%'}} src={this.state.mergedImage}/></div> : null}
				<div className='left center'>
					{/*<Menu selectedVariants={"ree"} behavior = {behavior} selectedLayers = {this.state.layerObject} handleCanvas = {this.handleCanvas} optionPackage = {itemPackage} grouping={grouping} categoryPackage = {categoryPackage} assetPackage={assetPackage}/>*/}
					<Menu selectedVariants={selectedVariants} behavior = {behavior} selectedLayers = {selectedOptions} handleCanvas = {this.handleCanvas} optionPackage = {itemPackage} grouping={grouping} categoryPackage = {categoryPackage} assetPackage={assetPackage}/>
				</div>
				<div className='middle'>
					<div onClick={this.handleReset} style={{'backgroundImage':`url("`+resetIcon+`")`,'zIndex':'5'}} className='reset'/>
					{this.state.mergedImage?<div>
					<a href={this.state.mergedImage} download="myClove">
						<div onClick={this.closeMerge} style={{'backgroundImage':`url("`+downloadIcon+`")`,'zIndex':'5','backgroundColor': '#68dffc'}} className='merge'/>
					</a>
					<a  href='https://www.patreon.com/junepurrr' target='_blank'><div style={{'backgroundImage':`url("`+patreonIcon+`")`,'zIndex':'5'}} className='patreon'/></a>
					<a  href='https://www.webtoons.com/en/romance/subzero/list?title_no=1468' target='_blank'><div style={{'backgroundImage':`url("`+webtoonIcon+`")`,'zIndex':'5'}} className='webtoon'/></a>
					</div>
					:<div onClick={this.handleMergeImages} style={{'backgroundImage':`url("`+cameraIcon+`")`,'zIndex':'5'}} className='merge'/>}
					<div className='middle center'><Picture imageStack = {newImageStack}/>
				</div>
				</div>
			</div>
		)
	}

}

export default Canvas