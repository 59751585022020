import React from 'react'
import './AnimatedMenu.css'
import back from './otherIcons/back-arrow.svg'
import '@frsource/frs-hide-scrollbar';

class Categories extends React.Component {

	render() {
		const {categorySelected,categoryPackage,handleCategorySelect} = this.props
		let className = 'categoryBox'
		if(categorySelected !== null) {
			className +=' catSelected'
		}
		const retCategories = Object.keys(categoryPackage).map((key)=>{
			const style = {
				'backgroundImage':`url("`+categoryPackage[key]+`")`,
			}
			let className = 'category icon'
			if(categorySelected !== null) {
				className += ' catSelected'
			} if(categorySelected === key) {
				className += ' onTop'
			}
			return <div key = {key}category={key} onClick={handleCategorySelect} className = {className} style = {style}/>
			})
		//console.log(retCategories);
		//order the array
		//dress top shoes neckalce fan background
		let groupingOrder = ['bottoms','tops','shoes','accessories','hands','backgrounds']
		let orderedRetCategories = [6];
		//console.log(retCategories[0].key);
		retCategories.forEach((item) => {
			//console.log(item);
			//console.log(groupingOrder.indexOf(item.key));
			let indexNumber = groupingOrder.indexOf(item.key)
			if(indexNumber >= 0) {
				orderedRetCategories[indexNumber] = item;
			}
		})
		return <div className={className}>{orderedRetCategories}</div>
	}
}

class Variant extends React.Component {
	render() {
		const {selectedVariants,handleVariantSelect,grouping,categorySelected,variantPackage} = this.props
		let className = 'variant menu'
		let retVariants = null;
		if(categorySelected !== null) {
			className += ' catSelected'
			retVariants = grouping[categorySelected].map((key) => {
				let className = 'variant icon'
				const style = selectedVariants.includes(key)?{
					'backgroundImage':`url("`+variantPackage[key]+`")`,
					'boxShadow':'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
				}:{
					'backgroundImage':`url("`+variantPackage[key]+`")`,

				}
				// const style = {'backgroundImage':`url("`+variantPackage[key]+`")`,}
				return <div key={key} onClick={handleVariantSelect} variant={key} className = {className} style = {style}/>
			})
		}
		return <div className = {className}>
			<div className= 'frs-hide-scroll' style={{'height':'100%','overflowY':'scroll'}}>{retVariants}</div>
		</div>
	}
}

class Option extends React.Component {
	render() {
		const {behavior,selectedLayers,variantSelected,optionPackage} = this.props
		let retOptions = null
		let className = null
		let linkedMode = behavior[variantSelected]&&behavior[variantSelected]['linked']
		let linkedRet;
		let linkedVariant = linkedMode? behavior[variantSelected]['linked']:null

		if(variantSelected!==null) {
			className = linkedMode?'variant menu catSelected linked':'variant menu catSelected'
			retOptions = Object.keys(optionPackage[variantSelected]).map((key) => {
				let className = 'variant icon'
				let style = {'backgroundImage':`url("`+optionPackage[variantSelected][key]['icon']+`")`,}
				// if(selectedLayers[variantSelected]&&selectedLayers[variantSelected]===optionPackage[variantSelected][key]['item']) {
				// 	style['boxShadow'] = 'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
				// } if(behavior[variantSelected]&&behavior[variantSelected]['multi-select']) {
				// 	if(selectedLayers[variantSelected]&&selectedLayers[variantSelected].includes(optionPackage[variantSelected][key]['item'])) {
				// 		style['boxShadow'] = 'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
				// 	}
				// }

				if(selectedLayers.includes(optionPackage[variantSelected][key]['item'])) {
					style['boxShadow'] = 'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
				}

				return <div key={key} variant={variantSelected} url={optionPackage[variantSelected][key]['item']} onClick={this.props.handleCanvas} option={key} className = {className} style = {style}/>
			})

			if(linkedMode) {
				linkedRet = Object.keys(optionPackage[linkedVariant]).map((key) => {
					let className = 'variant icon'
					let style = {'backgroundImage':`url("`+optionPackage[linkedVariant][key]['icon']+`")`,}
					// if(selectedLayers[linkedVariant]&&selectedLayers[linkedVariant]===optionPackage[linkedVariant][key]['item']) {
					// 	style['boxShadow'] = 'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
					// } if(behavior[linkedVariant]&&behavior[linkedVariant]['multi-select']) {
					// 	if(selectedLayers[linkedVariant]&&selectedLayers[linkedVariant].includes(optionPackage[linkedVariant][key]['item'])) {
					// 		style['boxShadow'] = 'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
					// 	}
					// }

					if(selectedLayers.includes(optionPackage[linkedVariant][key]['item'])) {
						style['boxShadow'] = 'inset 0 0 0 .5vh rgba(160, 243, 174, 1.0)'
					}
					
					return <div key={key}variant={linkedVariant} url={optionPackage[linkedVariant][key]['item']} onClick={this.props.handleCanvas} option={key} className = {className} style = {style}/>
				})
			}
		}


		return <div className = {className}>
			{/*<div style={{'height':'100%'}}><div style={{'transform': 'translateY(90vh)'}}>{retOptions}</div></div>*/}
			<div className = 'frs-hide-scroll' style={{'height':'100%','overflowY':'scroll'}}>{retOptions}</div>
			{linkedMode?<div className= 'frs-hide-scroll' style={{'height':'100%','overflowY':'scroll'}}>{linkedRet}</div>:null}
		</div>
	}
}

class Menu extends React.Component {

	constructor(props) {
		super(props)
		this.state ={
			categorySelected : null,
			variantSelected : null,
			optionSelected : null,
		}
		this.handleCategorySelect = this.handleCategorySelect.bind(this)
		this.handleVariantSelect = this.handleVariantSelect.bind(this)
		this.handleCanvas = this.handleCanvas.bind(this)
		this.handleCloseButton = this.handleCloseButton.bind(this)
	}

	handleCategorySelect(event) {
		const category = event.target.getAttribute('category')
		const currentCategory = this.state.categorySelected
		if(currentCategory === category) {
			this.setState({categorySelected:null})
			this.setState({variantSelected:null})
		} else {
			this.setState({categorySelected : category,variantSelected: null})
		}
	}

	handleVariantSelect(event) {
		const variant = event.target.getAttribute('variant')
		this.setState({variantSelected : variant})
	}

	handleCanvas(event) {
		let asset = event.target.getAttribute('option')
		let itemUrl = event.target.getAttribute('url')
		let variant  = event.target.getAttribute('variant')

		this.props.handleCanvas(variant,asset,itemUrl)
	}

	handleCloseButton(event) {
		if(this.state.variantSelected) {
			this.setState({variantSelected: null})
			return null
		} if(this.state.categorySelected) {
			this.setState({categorySelected:null})
		}
		
	}

	render() {
		const {behavior,selectedVariants,selectedLayers,optionPackage,grouping,categoryPackage,assetPackage} = this.props
		return <div className='center'>
			<div className = 'leftMenu' style={{
				'height' : '100vh',
				'width' : '30vw',
			}}>
				<div className='boundingBox'>
					{this.state.categorySelected ?<div onClick = {this.handleCloseButton} className="closeButton" style ={{'backgroundImage': `url("`+back+`")`}}/> : null}
					<Categories categorySelected = {this.state.categorySelected} handleCategorySelect={this.handleCategorySelect} categoryPackage = {categoryPackage}/>
					<Variant selectedVariants={selectedVariants} handleVariantSelect={this.handleVariantSelect} grouping = {grouping} variantPackage = {assetPackage} categorySelected = {this.state.categorySelected}/>
					<Option behavior={behavior} selectedLayers={selectedLayers} handleCanvas = {this.handleCanvas} optionPackage={optionPackage} variantSelected = {this.state.variantSelected} handleOptionSelect = {this.handleOptionSelect}/>

				</div>
			</div>
		</div>
	}
}

export default Menu